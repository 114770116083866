@import "src/assets/css/variables";

.dashboard-search {
    position: relative;

    &-input {
        font-size: 16px;
        line-height: 19px;
        color: $textPrimary;
        width: 400px;
        padding: 16px 24px;
        background-color: $primaryLight;
        border-radius: 16px;
        border: none;
        outline: none;
        display: flex;

        &::before {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $success;
        }

        &::placeholder {
            font-size: 16px;
            line-height: 19px;
            color: $textPrimary;
        }
    }

    &-icon {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 20px;
        height: 20px;
        cursor: pointer;

        transform: translateY(-50%);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.94308 9.17176C5.94308 5.69356 8.72672 2.86352 12.1488 2.86352C15.5704 2.86352 18.3543 5.69356 18.3543 9.17176C18.3543 12.6502 15.5704 15.4803 12.1488 15.4803C8.72672 15.4803 5.94308 12.6502 5.94308 9.17176ZM3.79763 9.17174C3.79763 11.1305 4.46064 12.9342 5.56924 14.3692L0.34748 19.7027C-0.120506 20.1774 -0.115112 20.9415 0.359618 21.4095C0.594556 21.6415 0.900702 21.7569 1.20685 21.7569C1.51839 21.7569 1.82993 21.6372 2.06622 21.3977L7.30173 16.0499C8.66955 17.0404 10.3424 17.626 12.1488 17.626C16.7537 17.626 20.4998 13.8335 20.4998 9.17174C20.4998 4.51022 16.7537 0.717773 12.1488 0.717773C7.54395 0.717773 3.79763 4.51022 3.79763 9.17174Z' fill='%230F0F1A'/%3E%3C/svg%3E");
    }
}
