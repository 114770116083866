table.dashboard-accounts-billing-table {
    width: 100%;
}

table.dashboard-accounts-billing-table {
    border-collapse: collapse;

    thead th {
        font-size: 14px;
        line-height: 16px;
        color: $neutral;
        font-weight: 700;
        padding: 10px 25px;
    }

    tbody tr {
        border-radius: 4px;

        td {
            padding: 25px;
            font-size: 14px;
            line-height: 20px;
            color: $neutral;
            height: 100%;

            strong {
                font-size: 16px;
            }

            &:first-of-type {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &:last-of-type {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        &:nth-of-type(2n-1) td {
            background-color: $neutralLight;
        }
    }
}