.featured-list {
    display: flex;
    justify-content: space-around;
    padding: 0 70px;
    width: 100%;

    &-item {
        display: flex;
        flex-direction: column;
        width: 220px;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        &-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #001849;
        }

        &-description {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        &-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 110px;
            flex-direction: column;
            text-align: center;
            gap: 15px;
        }
    }
}
