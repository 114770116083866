.scheduler-empty {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    p {
        margin-top: 15px;
    }
}