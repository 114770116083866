@import "src/assets/css/variables";

.button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $white;
    padding: 16px 32px;
    width: fit-content;
    transition: .3s ease-in-out;

    a {
        color: $white;
    }

    &.rounded {
        border-radius: 16px;
    }

    &:hover {
        cursor: pointer;
        color: #FFFFFF;
    }

    &-primary {
        background: $gradientPrimary;
        border: none;
        outline: none;

        &.disabled {
            background: #DCDFE8;
            pointer-events: none;
        }
    }

    &-secondary {
        padding: 10px 20px;
        border: 1px solid #DCDCE4;
        border-radius: 4px;
        text-align: center;

        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #32324D;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover {
            color: #32324D;
        }

        &.disabled {
            opacity: 0.5;
            cursor: default;
        }
    }

    &-light {
        font-size: 16px;
        line-height: 19px;
        color: #001849;
        padding: 16px 24px;
        background-color: #FBF8FF;
        border-radius: 16px;
        border: none;
        outline: none;
        display: flex;

        &:hover {
            color: #001849;
        }
    }
}