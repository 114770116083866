.dashboard-tabs {
    box-shadow: 0px 2px 30px rgba(0, 24, 73, 0.05);

    &-content {
        padding: 25px 80px;
        display: none;

        &.active {
            display: block;
        }
    }

    &-list {
        display: flex;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        box-shadow: inset #DCDCE4 0px -1px 0;
        background-color: inherit;

        &-item {
            background: #F6F6F9;
            min-width: 260px;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: $neutral;
            text-align: center;
            padding: 15px;
            box-shadow: inset #DCDCE4 0px -1px 0;
            cursor: pointer;
            transition: .3s;

            &:first-of-type {
                border-top-left-radius: 6px;
            }

            &:last-of-type {
                border-top-right-radius: 6px;
                border-right: none;
            }

            &.active {
                box-shadow: inset $white 0px -1px 0, 0px 2px 30px rgba(0, 24, 73, 0.05);;
                color: $siteSecondary;
                background-color: $white;
            }
        }
    }
}