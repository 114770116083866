@import "src/assets/css/variables";

.dashboard-table {
    width: 100%;

    &-sort-icon {
        width: 10px;
        margin-left: 5px;
        transition: .1s ease-in-out;

        &.reverse {
            transform: rotate(180deg);
        }
    }

    &-controls {
        display: flex;
        gap: 25px;
        justify-content: end;
    }

    &-cell {
        position: relative;
        display: flex;
        gap: 10px;
        align-items: center;

        &::before {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $success;
        }

        &.inactive::before {
            background-color: $danger;
        }
    }

    &-checkbox {
        user-select: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-dec {
            display: flex;
            align-items: center;

            &::before {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid #adb5bd;
                border-radius: 0.25em;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 55% 55%;
                transition: .3s ease-in-out;
                cursor: pointer;
            }
        }

        input[type="checkbox"] {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked+div::before {
                border-color: $secondary;
                background-color: $secondary;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            }
        }
    }

    th {
        font-weight: 700;
        font-size: 11px;
        line-height: 16px;
        color: $neutral;
        text-transform: uppercase;
        padding: 15px 0;
        border-bottom: 1px solid $neutralLight;
    }

    td {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $neutralDark;
        padding: 15px 0;
        border-bottom: 1px solid $neutralLight;
        position: relative;
    }
}