@import "src/assets/css/variables";

.sidebar {
    padding: 30px 55px;
    display: flex;
    flex-direction: column;

    &-user {
        display: flex;
        gap: 15px;

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .sidebar-user-name {
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                color: $textDark;
            }

            .sidebar-user-role {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: rgba(0, 0, 0, 0.5);
            }
        }

        &-image img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }
    }

    &-navigation {
        margin-top: 25px;
        display: flex;
        flex-direction: column;

        &-item {
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: $textDark;
            padding: 10px 5px;

            &.logout {
                cursor: pointer;
            }

            &.active {
                background-color: $grayLight;
            }
        }
    }
}