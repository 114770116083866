@import "src/assets/css/variables";

.dashboard {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: $backgroundPrimary;
    transition: .5s ease-in-out;
    opacity: 1;

    &-disabled {
        opacity: 0.5;
        pointer-events: none;
        transition: .5s ease-in-out;
    }

    &-content {
        width: 100%;
        margin-left: 250px;
        margin-top: 101px;
    }

    &-sidebar {
        height: 100vh;
        background: #fff;
        position: fixed;
        width: 250px;
        top: 101px;
    }

    &-title {
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: $primaryPurple;
        display: flex;
        justify-content: center;
        position: relative;
        margin: 35px 50px 0;

        &-action {
            position: absolute;
            right: 0;
        }
    }

    &-subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        color: #001849;
    }

    h4.dashboard-title {
        font-size: 17px;
        margin: 0;
    }

    &-card {
        margin: 15px 50px;
        background-color: $white;
        padding: 25px 35px;
        box-shadow: 0px 2px 30px rgba(0, 24, 73, 0.05);
        min-height: 450px;
        width: -webkit-fill-available;
        position: relative;
        z-index: 1;

        &-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;
        }

        &-secondary {
            background-color: #FBF8FF;
        }
    }

    &-pagination {
        display: flex;
        justify-content: space-between;
        margin: 0 90px;

        &-item {
            border-radius: 4px;

            &-next a, &-prev a {
                display: block;
                width: 32px;
                height: 32px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 20%;
                background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 8.825L2.2915 5L6 1.175L4.8583 0L0 5L4.8583 10L6 8.825Z' fill='%238E8EA9'/%3E%3C/svg%3E%0A");
            }

            &-next {
                transform: rotate(180deg);
            }

            a {
                transition: .3s ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                line-height: 16px;
                width: 32px;
                height: 32px;
                color: $neutralDark;
            }

            &.active {
                background: #FFFFFF;

                a {
                    box-shadow: 0px 1px 4px rgba(26, 26, 67, 0.1);
                    color: $secondaryDark;
                    font-weight: bold;
                }
            }
        }


        &-list {
            &-container {
                display: flex;
                list-style: none;
                margin-bottom: 0;
            }
        }

        &-count {
            display: flex;
            gap: 10px;
            align-items: center;

            &-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: fit-content;
                padding: 8px 10px;
                background: #FFFFFF;
                box-shadow: 0px 1px 4px rgba(26, 26, 67, 0.1);
                border-radius: 4px;
            }

            select {
                border: none;
                outline: none;
                height: fit-content;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                cursor: pointer;
            }

            span {
                color: $neutralSemi;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}