.dashboard-form-card {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 4px rgba(33, 33, 52, 0.1);
    border-radius: 4px;

    &-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $neutralDark;
        margin-bottom: 15px;
    }

    &-info {
        color: $neutralDark;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 15px;
    }

    .row {
        margin-bottom: 15px;
    }
}

.form-row {
    margin-bottom: 15px;
}