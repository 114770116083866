.dashboard-timepicker {
    width: 100%;

    input {
        background-color: #FFFFFF;
        border: 1px solid #DCDCE4;
        border-radius: 4px;
        padding: 10px 15px;
        outline: none;
        height: auto;

        margin: 5px 0;

        font-size: 14px;
        line-height: 20px;
        width: 100%;

        &:focus {
            border: 1px solid #2684FF;
        }

        &::placeholder {
        }
    }

    &-popup {
        top: 400px !important;
    }
}

.rc-time-picker-panel-inner {
    border: none;
    box-shadow: none;
    filter: drop-shadow(0px 2px 15px rgba(33, 33, 52, 0.1));
}

li.rc-time-picker-panel-select-option-selected {
    color: #4945FF;
    background: #F0F0FF;
}
.rc-time-picker-panel-input {
    font-weight: 700;
}