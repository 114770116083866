.account-campaigns-edit {
    margin-left: 10px;
    padding: 5px 20px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #909090;
    border: 2px solid #D8D8D8;
    border-radius: 4px;
    text-align: center;
}