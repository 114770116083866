@import "variables";
@import "font/font";
@import "components/dashboard-form/form";
@import "components/dashboard-tabs/tabs";
@import "components/accounts/accounts-billing";
@import "components/dashboard-analytics/dashboard-analytics";
@import "~bootstrap/scss/bootstrap";

::-webkit-scrollbar {
    width: 10px;
    background-color: $white;
}

::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: $dark;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: $body-text;
    font-size: 1rem;
    line-height: 1.6;
    color: #626262;

    background-color: $backgroundPrimary;
}

a {
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

.dashboard-toast {
    font-family: $headline-text;
}

.__react_modal_image__modal_container {
    top: 100px !important;
}

.dashboard-badge {
    background: $siteSecondary;
    color: $white;
    border-radius: 6px;
    display: flex;
    padding: 3px 10px;
}

.page-loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.data-loader {
    height: 325px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ck-tooltip__text {
    display: none !important;
}

.ck.ck-editor {
    width: inherit;
    margin: 5px 0;
}

.ck.ck-button.ck-on:not(.ck-disabled):hover, a.ck.ck-button.ck-on:not(.ck-disabled):hover {
    background-color: transparent !important;
}

.ck.ck-editor__editable_inline {
    border: 1px solid #DCDCE4 !important;
    padding: 0 19px;
}

.ck-toolbar {
    border-radius: 4px 4px 0 0 !important;
    border: 1px solid #DCDCE4 !important;
    border-bottom: 0 !important;
}

.ck-content {
    border-radius: 0 0 4px 4px !important;
}

.ck-editor__editable {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #32324D;
}

.quill {
    width: inherit;
    margin: 5px 0;
}

.ql-toolbar.ql-snow {
    border: 1px solid #DCDCE4;
    border-radius: 4px 4px 0 0;
}

.ql-container.ql-snow {
    border: 1px solid #DCDCE4;
    border-top: 0;
    border-radius:  0 0 4px 4px;
}

.ql-editor {
    font-weight: 500;
}

.ql-empty {
    display: none !important;
}

.ql-blank::before {
    font-style: normal !important;
    color: #8E8EA9 !important;
}

.link-dark {
    a {
        color: #32324D;
    }
}

.text-orange {
    color: #eb8735 !important;
}

.header-input select {
    margin: 0;
}

.dashboard-event-badge {
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    color: #333333;
    font-size: 13px;
    padding: 5px 10px;
    line-height: initial;
    font-weight: 600;
    border: 1px solid #D9D8FF;
    background-color: #F0F0FF;
    border-radius: 4px;
}

.top-15 {
    top: 15px;
}

.dashboard-date-badge {
    background-color: #F0F0FF;
    border-radius: 4px;
    padding: 4px 0;
    display: flex;
    font-size: 13px;
    justify-content: center;
    width: 46%;
    text-align: center;
}

.disabled-day {
    pointer-events: none;
    opacity: 0.5;
}

.cursor-pointer {
    cursor: pointer;
}