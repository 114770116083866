.dashboard-events-filter {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding-bottom: 60px;

    &-date {
        display: flex;
        flex-direction: column;

        &-controls {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;

        &-number {
            font-size: 14px;
            line-height: 17px;
            color: #001849;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        &.centered {
            height: auto;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-item {
            display: flex;
            justify-content: space-between;

            &-controls {
                display: flex;
                justify-content: space-between;
            }

            &-content {
                width: 70%;
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            &-image {
                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 15px;

                img {
                    background: #F1F2F6;
                    width: 100%;
                    border-radius: 8px;
                }
            }

            &-row {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                line-height: 17px;
                color: #001849;
                gap: 5px;

                span {
                    opacity: 0.5;
                }
            }
        }
    }
}