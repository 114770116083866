// Fonts

$headline-text: "Lato", sans-serif;
$body-text: "Lato", sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

// Colors

$primary: #FF3E92;
$primaryLight: #FBF8FF;
$primaryDark: #9E3EFF;
$primaryPurple: #0F0F1A;

$secondaryDark: #271FE0;
$siteSecondary: #4945FF;
$secondary: #4945FF;
$secondaryLight: #F0F0FF;

$danger: #D02B20;
$pink: #F53E9E;
$success: #328048;
$black: #231F20;
$gray: #7A7A7A;
$grayLight: #D8D8D8;
$white: #FFFFFF;
$light: #F7F7F7;
$border: #DCDFE8;

$neutral: #666687;
$neutralLight: #F6F6F9;
$neutralSemi: #8E8EA9;
$neutralDark: #32324D;

$textPrimary: #001849;
$textDark: #011235;
$textSecondary: #949FAE;

$backgroundPrimary: #F1F2F6;

$gradientPrimary: linear-gradient(90deg, #FF3E92 0%, #9E3EFF 139.81%, #FF3E92 273.44%, #9E3EFF 394.75%);
$gradientPrimaryTop:  linear-gradient(0deg, #FF3E92 1.22%, #9E3EFF 97.56%);
