.dashboard-analytics-table {
    width: -webkit-fill-available;
    margin: 0 50px;
    border-collapse:separate;
    border-spacing: 0 15px;

    &-expand {
        position: relative;
        bottom: 15px;
        z-index: 10;
        background-color: #d3cddf3d;
    }

    thead {
        border-top: 0;

        th {
            border-top: 0;
            padding: 25px;

            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #001849;
        }
    }

    tbody {
        td {
            padding: 30px 25px;

            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #001849;

            &:first-of-type {
                background-color: #d8d8d8;
                color: #000;
            }
        }
    }

    &-content {
        width: -webkit-fill-available;

        tbody td {
            opacity: 0.5;
            padding: 15px 15px;
        }

        thead th {
            padding: 15px 15px;
        }

        tr td:nth-child(1){
            opacity: 1;
        }

        tr td:nth-child(8){
            opacity: 1;
        }

        &-creative {
            width: 63px;
            height: 52px;

            background: #F1F2F6;
            border-radius: 16px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.dashboard-analytics-table-info {
    width: -webkit-fill-available;
    border-spacing: 0 15px;

    th {
        padding: 25px 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #001849;
        text-align: center;

        &:nth-child(3) {
            text-align: left;
        }
    }

    td {
        text-align: center;
        color: #001849;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        padding: 15px 0;

        &:nth-child(3) {
            text-align: left;
        }
    }
}