.dashboard-datepicker-header {
    background-color: #FFFFFF !important;
    display: flex !important;
    justify-content: start !important;
    gap: 10px !important;

    select {
        color: #32324D !important;
        font-size: 14px !important;
        line-height: 16px !important;
        font-weight: 700 !important;
        border: none !important;
        outline: none !important;
    }
}

.react-datepicker__triangle {
    display: none !important;
}
.react-datepicker__header {
    background-color: #FFFFFF !important;

    .react-datepicker__day-names {
        background-color: #FFFFFF !important;
        font-weight: 700 !important;
    }
}
.dashboard-datepicker {
    filter: drop-shadow(0px 2px 15px rgba(33, 33, 52, 0.1)) !important;
    border: none !important;
    background: #FFFFFF !important;
    border-radius: 4px !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    font-size: 12px !important;
    line-height: 32px !important;
    color: #212134 !important;
    width: 32px !important;
    height: 32px !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 4px !important;
    transition: .3s ease-in-out !important;
}
.react-datepicker__day {
    &:hover {
        background: rgba(240, 240, 255, 0.5) !important;
    }

    &--selected {
        background-color: #F0F0FF !important;
        color: #4945FF !important;
        font-weight: 700 !important;

        &:hover {
            background-color: #0000ff !important;
        }
    }
}

.react-datepicker__month {
    margin: 0 16px 16px !important;
}
.react-datepicker__header {
    margin: 16px 16px 0 !important;
    padding: 0 !important;
}
.dashboard-datepicker-header {
    margin-bottom: 10px !important;
}
.react-datepicker__day--outside-month {
    color: #8E8EA9 !important;
}
.react-datepicker__day--keyboard-selected {
    background-color: #F0F0FF !important;
    color: #4945FF !important;
    font-weight: 700 !important;
}
.react-datepicker__day--highlighted {
    background-color: #F0F0FF !important;
    color: #4945FF !important;
    font-weight: 700 !important;
}
.react-datepicker__day--keyboard-selected {
    background: #FFFFFF !important;
    color: #212134 !important;
    font-weight: 400 !important;

    &.react-datepicker__day--highlighted {
        background-color: #F0F0FF !important;
        color: #4945FF !important;
        font-weight: 700 !important;
    }
}
.react-datepicker-popper[data-placement^=bottom] {
    padding-top: 0 !important;
    z-index: 25 !important;
}

.react-datepicker__day--selected:hover {
    color: #4945FF !important;
    background-color: #F0F0FF !important;
}
.react-datepicker__day-names {
    margin-bottom: -1px !important;
}