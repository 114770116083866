.dashboard-media {
    display: flex;
    flex-direction: column;

    &-controls {
        margin: 15px 50px 0;
        display: flex;
        justify-content: space-between;

        &-list {
            display: flex;
            gap: 5px;
        }

        &-item {
            padding: 7px 12px;
            background: #FFFFFF;
            border: 1px solid #DCDCE4;
            border-radius: 4px;
            align-items: center;
            display: flex;
            gap: 10px;
            font-weight: 700;
            color: #32324D;
            font-size: 12px;
            line-height: 16px;

            select {
                color: #32324D;
                font-size: 12px;
                line-height: 16px;
                font-weight: 700;
                border: none;
                outline: none;
            }

            svg {
                width: 13px;
                height: 13px;
            }
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        row-gap: 15px;
        justify-content: space-between;
    }

    &-item {
        width: 24%;
        border: 0.5px solid #EAEAEF;
        box-shadow: 0px 1px 4px rgba(26, 26, 67, 0.1);
        border-radius: 4px;

        &-body {
            padding: 10px 15px;
        }

        &-type {
            border-radius: 4px;
            padding: 2px 7px;
            background-color: #EAEAEF;
            font-weight: 700;
            font-size: 11px;
            line-height: 16px;
            text-transform: uppercase;
        }

        &-title {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #4A4A6A;
        }

        &-header {
            display: flex;
            justify-content: space-between;
        }

        &-image {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            height: 145px;
            background-color: #acacb6;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-info {
            font-size: 12px;
            line-height: 16px;
            color: #8E8EA9;
        }

        &-filter {

        }
    }
    &-list-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: -webkit-fill-available;

        &-description {
            width: 600px;
            color: #9090A4;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }

        &-title {
            color: #0F0F1A;
            width: 300px;
            text-align: center;
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;
        }
    }
}