.dashboard-form-group {
    position: relative;

    &-multiselect {
        margin: 5px 0;
    }

    &-help {
        color: #666687;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
    }

    &-input {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;

        &.icon {
            z-index: 10;

            input {
                padding-left: 45px;
            }

            &-calendar::before {
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.71027 0.75C4.71027 0.335786 4.37448 0 3.96027 0C3.54605 0 3.21027 0.335786 3.21027 0.75V2.56643C1.71719 2.77751 0.537643 3.95845 0.337966 5.46484L0.251414 6.11779C0.23674 6.22849 0.222709 6.33926 0.20932 6.45008C0.173804 6.74405 0.405409 7 0.701515 7H17.219C17.5151 7 17.7467 6.74405 17.7112 6.45008C17.6978 6.33926 17.6837 6.22849 17.6691 6.11779L17.5825 5.46484C17.3828 3.95847 16.2033 2.77754 14.7103 2.56644V0.75C14.7103 0.335786 14.3745 0 13.9603 0C13.5461 0 13.2103 0.335786 13.2103 0.75V2.41763C10.3825 2.1658 7.538 2.1658 4.71027 2.41763V0.75Z' fill='%239090A4'/%3E%3Cpath d='M17.9048 8.97555C17.8961 8.70912 17.6759 8.5 17.4093 8.5H0.511132C0.244561 8.5 0.0243952 8.70912 0.0156405 8.97555C-0.043758 10.7832 0.0660439 12.5946 0.344778 14.3859C0.555787 15.7419 1.65745 16.7835 3.02319 16.9181L4.21619 17.0358C7.37124 17.3469 10.5492 17.3469 13.7043 17.0358L14.8973 16.9181C16.263 16.7835 17.3647 15.7419 17.5757 14.3859C17.8544 12.5946 17.9642 10.7832 17.9048 8.97555Z' fill='%239090A4'/%3E%3C/svg%3E%0A");
            }

            &-clock::before {
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9ZM9.75 4C9.75 3.58579 9.41421 3.25 9 3.25C8.58579 3.25 8.25 3.58579 8.25 4V9C8.25 9.25859 8.38321 9.49895 8.6025 9.636L11.6025 11.511C11.9538 11.7305 12.4165 11.6238 12.636 11.2725C12.8555 10.9212 12.7488 10.4585 12.3975 10.239L9.75 8.58431V4Z' fill='%239090A4'/%3E%3C/svg%3E%0A");
            }
            &-star::before {
                background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.865 0.996161C11.4796 0.33201 10.5205 0.332008 10.1351 0.996161L7.42107 5.67347C7.2307 6.00155 6.90165 6.22544 6.52659 6.28209L1.44027 7.05032C0.585991 7.17935 0.286331 8.25796 0.951637 8.80916L4.74073 11.9484C5.09147 12.239 5.25639 12.6973 5.17119 13.1448L4.18002 18.3499C4.02334 19.1727 4.88712 19.812 5.62827 19.4218L10.4177 16.9C10.7822 16.708 11.2179 16.708 11.5824 16.9L16.3718 19.4218C17.113 19.812 17.9768 19.1727 17.8201 18.3499L16.8289 13.1448C16.7437 12.6973 16.9087 12.239 17.2594 11.9484L21.0485 8.80916C21.7138 8.25796 21.4141 7.17935 20.5598 7.05032L15.4735 6.28209C15.0985 6.22544 14.7694 6.00155 14.579 5.67347L11.865 0.996161Z' fill='%239090A4'/%3E%3C/svg%3E%0A");            }

            &::before {
                content: '';
                position: absolute;
                z-index: 30;
                left: 15px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
            }
        }

        &-icon {
            position: absolute;
            width: 20px;
            height: 20px;

            right: 15px;

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.0303 1.28033C18.3232 0.987437 18.3232 0.512563 18.0303 0.21967C17.7374 -0.0732233 17.2626 -0.0732233 16.9697 0.21967L0.96967 16.2197C0.676777 16.5126 0.676777 16.9874 0.96967 17.2803C1.26256 17.5732 1.73744 17.5732 2.03033 17.2803L5.06535 14.2453C6.38317 14.8532 7.89185 15.25 9.5 15.25C12.1178 15.25 14.472 14.1986 16.1684 12.8966C17.0177 12.2448 17.7146 11.5209 18.2035 10.8163C18.6831 10.1252 19 9.39847 19 8.75C19 8.10153 18.6831 7.37481 18.2035 6.68368C17.7146 5.97913 17.0177 5.25519 16.1684 4.60335C15.8978 4.39567 15.6104 4.19437 15.308 4.00269L18.0303 1.28033ZM12.6287 6.68196L11.5307 7.77994C11.6713 8.07366 11.75 8.40264 11.75 8.75C11.75 9.99264 10.7426 11 9.5 11C9.15264 11 8.82366 10.9213 8.52994 10.7807L7.43196 11.8787C8.02478 12.2713 8.73569 12.5 9.5 12.5C11.5711 12.5 13.25 10.8211 13.25 8.75C13.25 7.98569 13.0213 7.27478 12.6287 6.68196Z' fill='%238E8EA9'/%3E%3Cpath d='M10.1692 5.05953C10.253 5.07462 10.3395 5.0498 10.3997 4.9896L12.3493 3.04007C12.4816 2.90772 12.4267 2.68281 12.2464 2.63252C11.379 2.39055 10.4571 2.25 9.5 2.25C6.88223 2.25 4.52801 3.30139 2.83162 4.60335C1.98232 5.25519 1.28544 5.97913 0.79649 6.68368C0.316861 7.37481 0 8.10153 0 8.75C0 9.39847 0.316861 10.1252 0.79649 10.8163C1.2138 11.4176 1.78257 12.0331 2.46829 12.6058C2.56614 12.6875 2.70993 12.6794 2.80008 12.5893L5.7396 9.64974C5.7998 9.58954 5.82462 9.50296 5.80953 9.41917C5.77042 9.20203 5.75 8.9784 5.75 8.75C5.75 6.67893 7.42893 5 9.5 5C9.7284 5 9.95203 5.02042 10.1692 5.05953Z' fill='%238E8EA9'/%3E%3C/svg%3E%0A");
            cursor: pointer;

            &.password {
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1339 6.01646C10.1339 7.19466 9.17879 8.14977 8.00059 8.14977C6.82239 8.14977 5.86727 7.19466 5.86727 6.01646C5.86727 4.83826 6.82239 3.88314 8.00059 3.88314C9.17879 3.88314 10.1339 4.83826 10.1339 6.01646Z' fill='%238E8EA9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5196 2.08494C13.8022 2.91327 14.9466 4.07193 15.8286 5.43592C15.9399 5.60849 15.9994 5.80938 16 6.01476C16.0006 6.22013 15.9422 6.42137 15.8319 6.59458C14.9532 7.9699 13.8112 9.13289 12.5292 9.95855C11.1163 10.8682 9.54626 11.3492 7.98928 11.3492C6.39729 11.3492 4.8673 10.8825 3.44564 9.96155C2.18298 9.14456 1.04999 7.98423 0.169664 6.60658C0.0553699 6.42925 -0.003626 6.22194 0.000172543 6.011C0.00397108 5.80007 0.0703926 5.59502 0.190997 5.42192C1.25932 3.89026 2.48931 2.7006 3.84697 1.88628C5.1783 1.08761 6.57195 0.682617 7.98928 0.682617C9.53393 0.682617 11.1006 1.16761 12.5196 2.08494ZM6.2228 8.6766C6.74903 9.02822 7.36771 9.21589 8.00061 9.21589C8.849 9.21492 9.66236 8.87747 10.2623 8.27757C10.8622 7.67767 11.1996 6.8643 11.2006 6.01591C11.2006 5.38302 11.0129 4.76433 10.6613 4.2381C10.3097 3.71187 9.80991 3.30172 9.22519 3.05952C8.64047 2.81732 7.99706 2.75395 7.37632 2.87742C6.75559 3.00089 6.18541 3.30566 5.73788 3.75319C5.29036 4.20071 4.98559 4.77089 4.86212 5.39163C4.73865 6.01236 4.80202 6.65577 5.04422 7.24049C5.28641 7.82521 5.69656 8.32498 6.2228 8.6766Z' fill='%238E8EA9'/%3E%3C/svg%3E%0A");
            }
        }

        input, textarea, select {
            background-color: $white;
            border: 1px solid #DCDCE4;
            border-radius: 4px;
            padding: 10px 15px;
            outline: none;

            margin: 5px 0;

            font-size: 14px;
            line-height: 20px;
            color: $neutralDark;
            width: 100%;

            &:focus {
                border: 1px solid #2684FF;
            }

            &::placeholder {
                color: $neutralSemi;
            }
        }

        select {
            padding: 10px;
        }
    }

    &-errors {
        font-size: 12px;
        line-height: 16px;
        color: $danger;
    }

    label {
        display: flex;
        flex-direction: column;
        color: $neutralDark;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
    }

    &-file {
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 0;
        border-radius: 8px;
        cursor: pointer;

        &-list {
            li {
                font-size: 12px;
                color: #666687;
                margin-bottom: 10px;
            }

            .dashboard-form-group-errors:not(:first-of-type) {
                display: none;
            }
        }

        &-info {
            display: flex;
            justify-content: center;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            padding-bottom: 5px;
        }

        &-preview {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            &-item {
                height: 200px;
                border-radius: 6px;
                padding: 10px;
                border: 1px solid #2684FF;
                position: relative;
                transition: 0.3s ease-in-out;
                display: flex;

                &-actions {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .button {
                        width: 100%;
                    }
                }

                &.static {
                    cursor: default;

                    &::before {
                        display: none;
                    }

                    &::after {
                        display: none;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    border-radius: 6px;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    transition: 0.2s ease-in-out;
                    opacity: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    opacity: 0;
                    transition: 0.2s ease-in-out;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 30px;
                    height: 30px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml, %3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.4271 1.75705C12.691 2.0209 12.691 2.43668 12.4271 2.70054L11.1158 4.00383L8.62118 1.50918L9.92447 0.197893C10.1883 -0.0659642 10.6041 -0.0659642 10.868 0.197893L12.4271 1.75705ZM0.625 12V9.50535L7.72513 2.4052L10.2198 4.89985L3.11964 12H0.625Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
                }

                &:hover {
                    &::before, &::after {
                        opacity: 1;
                    }
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}